"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var DoorIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_1785_8359)" },
            React.createElement("path", { d: "M14.5 13.5H13V2.5C13 2.23478 12.8946 1.98043 12.7071 1.79289C12.5196 1.60536 12.2652 1.5 12 1.5H4C3.73478 1.5 3.48043 1.60536 3.29289 1.79289C3.10536 1.98043 3 2.23478 3 2.5V13.5H1.5C1.36739 13.5 1.24021 13.5527 1.14645 13.6464C1.05268 13.7402 1 13.8674 1 14C1 14.1326 1.05268 14.2598 1.14645 14.3536C1.24021 14.4473 1.36739 14.5 1.5 14.5H14.5C14.6326 14.5 14.7598 14.4473 14.8536 14.3536C14.9473 14.2598 15 14.1326 15 14C15 13.8674 14.9473 13.7402 14.8536 13.6464C14.7598 13.5527 14.6326 13.5 14.5 13.5ZM12 2.5V13.5H11V2.5H12ZM4 2.5H10V13.5H4V2.5ZM9 8.25C9 8.39834 8.95601 8.54334 8.8736 8.66668C8.79119 8.79001 8.67406 8.88614 8.53701 8.94291C8.39997 8.99967 8.24917 9.01453 8.10368 8.98559C7.9582 8.95665 7.82456 8.88522 7.71967 8.78033C7.61478 8.67544 7.54335 8.5418 7.51441 8.39632C7.48547 8.25083 7.50032 8.10003 7.55709 7.96299C7.61386 7.82594 7.70999 7.70881 7.83332 7.6264C7.95666 7.54399 8.10166 7.5 8.25 7.5C8.44891 7.5 8.63968 7.57902 8.78033 7.71967C8.92098 7.86032 9 8.05109 9 8.25Z", fill: color })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1785_8359" },
                React.createElement("rect", { width: "16", height: "16", fill: "white" })))));
};
exports.default = DoorIcon;
