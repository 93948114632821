"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ArrowDownIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 20 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.9132 8.1633L10.6632 14.4133C10.5762 14.5007 10.4727 14.57 10.3587 14.6174C10.2447 14.6647 10.1226 14.689 9.99918 14.689C9.8758 14.689 9.75362 14.6647 9.63967 14.6174C9.52571 14.57 9.42222 14.5007 9.33512 14.4133L3.08512 8.1633C2.909 7.98718 2.81006 7.74831 2.81006 7.49923C2.81006 7.25016 2.909 7.01129 3.08512 6.83517C3.26124 6.65905 3.50011 6.56011 3.74918 6.56011C3.99826 6.56011 4.23713 6.65905 4.41325 6.83517L9.99997 12.4219L15.5867 6.83439C15.7628 6.65827 16.0017 6.55933 16.2507 6.55933C16.4998 6.55933 16.7387 6.65827 16.9148 6.83439C17.0909 7.01051 17.1899 7.24938 17.1899 7.49845C17.1899 7.74752 17.0909 7.9864 16.9148 8.16252L16.9132 8.1633Z", fill: color })));
};
exports.default = ArrowDownIcon;
