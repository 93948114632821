"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLORS = void 0;
exports.COLORS = {
    primary: "#084AE9",
    paper: "#F5F8FC",
    cn: {
        primary: "#000000", // black
        secondary: "#4B5563", // gray600
        tertiary: "#6B7280", // gray500
        disabled: "#9CA3AF", // gray400
        onColor: "#FFFFFF", // white
        accent: "var(--color-blue750)", // blue750
        lightAccent: "var(--color-blue200)", // blue200
        positive: "#46642D", // green600
        warning: "#D06B2F", // orange500
        negative: "#E44F52",
        lightGray: "#949DB2",
    },
    bg: {
        primary: "#FFFFFF", // white
        secondary: "#F8F8F8", // gray50
        tertiary: "#EEEEEE", // gray100
        accent: "var(--color-blue750)", // blue750
        lightAccent: "var(--color-blue200)", // blue200
        neutral: "#F4F8FB", // blue25
        positive: "#E1EECE", // green100
        warning: "#F9EDDF", // orange100
        negative: "#FDD2D2", // red100
        lightBlue: "#EDF7FF", // blue50
    },
    border: {
        opaque: "#E5E7EB", // gray200
        transparent: "rgba(0, 0, 0, 0.08)", // black at 8% opacity
        selected: "#000000", // black
    },
    overlay: {
        100: "rgba(255, 255, 255, 0.08)", // white at 8% opacity
        200: "rgba(255, 255, 255, 0.16)", // white at 16% opacity
        950: "rgba(255, 255, 255, 0.90)", // white at 90% opacity
        inverse100: "var(--Overlay-Inverse100)", // black at 8% opacity
        inverse200: "var(--Overlay-Inverse200)", // black at 16% opacity
        darken: "rgba(0, 0, 0, 0.05)",
    },
};
