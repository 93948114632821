"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.textColorMap = exports.backgroundColorMap = void 0;
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
exports.backgroundColorMap = {
    borderless: "bg-none",
    transparent: "bg-overlay-inverse100",
    overlay: "bg-overlay-inverse100",
    neutral: "bg-bg-neutral",
    positive: "bg-bg-positive",
    warning: "bg-bg-warning",
    negative: "bg-bg-negative",
};
exports.textColorMap = {
    borderless: "text-cn-tertiary",
    transparent: "text-cn-tertiary",
    overlay: "text-cn-onColor",
    neutral: "text-cn-accent",
    positive: "text-cn-positive",
    warning: "text-cn-warning",
    negative: "text-cn-negative",
};
var Badge = function (_a) {
    var label = _a.label, icon = _a.icon, _b = _a.size, size = _b === void 0 ? "sm" : _b, _c = _a.type, type = _c === void 0 ? "transparent" : _c, textClassName = _a.textClassName, className = _a.className, style = _a.style;
    var backgroundColor = exports.backgroundColorMap[type];
    var textColor = exports.textColorMap[type];
    var isSmall = size === "sm";
    return (React.createElement("div", { className: (0, tailwind_merge_1.twMerge)("flex w-fit flex-row items-center gap-1 rounded-[20px] backdrop-blur-md", isSmall ? "px-2 py-1" : "px-1.5 py-0.5", backgroundColor, className), style: style },
        icon && React.createElement("div", null, icon),
        label && (React.createElement("div", { className: "whitespace-nowrap ".concat(isSmall ? "label-sm" : "label-xs", " ").concat(textColor, " ").concat(textClassName || "") }, label))));
};
exports.default = Badge;
