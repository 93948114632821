"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var PhiaCircleIcon = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? "white" : _b, _c = _a.size, size = _c === void 0 ? 12 : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.00108 0.0192871C2.72018 0.0192871 0.0604858 2.67898 0.0604858 5.95988C0.0604858 9.24078 2.72018 11.9005 6.00108 11.9005C9.28198 11.9005 11.9417 9.24078 11.9417 5.95988C11.9417 2.67898 9.28198 0.0192871 6.00108 0.0192871ZM8.70626 4.46139C8.70626 3.3967 8.18779 2.64679 7.10457 2.64679C6.81503 2.64679 6.49896 2.69985 6.18229 2.78867C6.17227 2.79148 6.1628 2.78266 6.16495 2.77248L6.2821 2.21756C6.2849 2.20431 6.27167 2.19336 6.25918 2.19859L5.2575 2.61768C5.2473 2.62195 5.23985 2.63097 5.23758 2.6418L5.10972 3.2527C5.10656 3.26777 5.09725 3.28084 5.08408 3.28881C4.86547 3.4212 4.651 3.57048 4.43781 3.73665C4.4285 3.74391 4.42485 3.7563 4.42873 3.76745L4.46841 3.88177C4.47401 3.89789 4.49274 3.90513 4.50776 3.89702C4.67956 3.80421 4.8439 3.72133 5.00473 3.64837C5.01505 3.64369 5.02625 3.65277 5.02385 3.66384C4.63428 5.46434 4.31749 6.92935 3.92132 8.7638C3.91572 8.78971 3.91032 8.81482 3.90507 8.83917C3.76789 9.47613 3.74316 9.59093 3.46194 9.62994C3.45333 9.63147 3.44644 9.63817 3.44502 9.6468L3.43398 9.70297C3.4323 9.71321 3.44019 9.7225 3.45056 9.7225H5.02369C5.03192 9.7225 5.03894 9.71654 5.04027 9.70842L5.0488 9.65634C5.05035 9.64685 5.04343 9.63801 5.03393 9.63652C4.73229 9.61316 4.78076 9.38459 4.8902 8.86861C4.89766 8.83343 4.90541 8.79692 4.91335 8.75904C5.13415 7.70524 5.14457 7.64991 5.14501 7.64744L5.14506 7.64723C5.14827 7.63212 5.16416 7.62341 5.1788 7.6284C5.37426 7.69499 5.66715 7.72025 5.81766 7.72025C7.56735 7.72025 8.70626 6.05378 8.70626 4.46139ZM5.99357 7.43373C6.94547 7.43373 7.75251 5.97972 7.75251 4.54471C7.75251 3.63741 7.40097 3.16483 6.6603 3.16483C6.47959 3.16483 6.30007 3.19129 6.08451 3.2528C6.07468 3.25561 6.06724 3.26367 6.06515 3.27367L6.06515 3.27368L5.43807 6.24821C5.34111 6.70815 5.32304 7.43373 5.99357 7.43373Z", fill: color })));
};
exports.default = PhiaCircleIcon;
