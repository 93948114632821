"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var AppleIcon = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { className: className, width: "25", height: "24", viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_894_5099)" },
            React.createElement("path", { d: "M20.5705 17.5861C20.268 18.2848 19.91 18.928 19.4952 19.5193C18.9298 20.3255 18.4668 20.8835 18.1101 21.1934C17.557 21.702 16.9644 21.9625 16.3299 21.9773C15.8743 21.9773 15.325 21.8477 14.6855 21.5847C14.0439 21.323 13.4543 21.1934 12.9152 21.1934C12.3497 21.1934 11.7433 21.323 11.0947 21.5847C10.4451 21.8477 9.92179 21.9847 9.52168 21.9983C8.91318 22.0242 8.30665 21.7563 7.70124 21.1934C7.31483 20.8563 6.83151 20.2786 6.25252 19.4601C5.6313 18.586 5.12057 17.5725 4.72046 16.417C4.29196 15.1689 4.07715 13.9603 4.07715 12.7902C4.07715 11.4498 4.36677 10.2938 4.94688 9.32509C5.40279 8.54696 6.00931 7.93316 6.76843 7.48255C7.52754 7.03195 8.34776 6.80233 9.23107 6.78763C9.71439 6.78763 10.3482 6.93714 11.1358 7.23096C11.9212 7.52576 12.4255 7.67526 12.6466 7.67526C12.812 7.67526 13.3722 7.50045 14.3219 7.15195C15.22 6.82874 15.978 6.69492 16.599 6.74764C18.2817 6.88343 19.5458 7.54675 20.3865 8.74177C18.8816 9.6536 18.1372 10.9307 18.152 12.5691C18.1656 13.8452 18.6286 14.9071 19.5384 15.7503C19.9508 16.1417 20.4112 16.4441 20.9236 16.6589C20.8125 16.9812 20.6952 17.2898 20.5705 17.5861ZM16.7113 2.40011C16.7113 3.40034 16.3459 4.33425 15.6175 5.19867C14.7386 6.22629 13.6754 6.8201 12.5225 6.7264C12.5078 6.60641 12.4992 6.48011 12.4992 6.3474C12.4992 5.38718 12.9173 4.35956 13.6596 3.51934C14.0302 3.09392 14.5015 2.74019 15.0731 2.45801C15.6435 2.18005 16.183 2.02632 16.6904 2C16.7052 2.13371 16.7113 2.26744 16.7113 2.4001V2.40011Z", fill: "black" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_894_5099" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", transform: "translate(0.5)" })))));
};
exports.default = AppleIcon;
