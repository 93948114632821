"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var FilterIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 18 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M18.304 3.74297C18.1816 3.46613 17.9813 3.23082 17.7277 3.06564C17.474 2.90045 17.1778 2.81251 16.8751 2.8125H3.12509C2.82243 2.81253 2.5263 2.90046 2.27266 3.06561C2.01903 3.23075 1.81882 3.46601 1.69636 3.74279C1.5739 4.01957 1.53446 4.32595 1.58283 4.62472C1.6312 4.92349 1.7653 5.20178 1.96884 5.42578L1.97822 5.43672L7.18759 10.9961V16.875C7.18755 17.1579 7.2643 17.4355 7.40966 17.6781C7.55503 17.9208 7.76354 18.1195 8.01296 18.2529C8.26238 18.3864 8.54335 18.4496 8.8259 18.4359C9.10845 18.4222 9.38197 18.332 9.61728 18.175L12.1173 16.5086C12.3313 16.3658 12.5068 16.1723 12.6281 15.9454C12.7494 15.7185 12.8128 15.4651 12.8126 15.2078V10.9961L18.0212 5.43672L18.0306 5.42578C18.2342 5.2019 18.3685 4.92369 18.417 4.62495C18.4656 4.32622 18.4263 4.01981 18.304 3.74297ZM11.3602 9.80391C11.0882 10.0932 10.937 10.4755 10.9376 10.8727V15.0406L9.06259 16.2906V10.8727C9.06315 10.4755 8.91196 10.0932 8.63994 9.80391L3.84619 4.6875H16.154L11.3602 9.80391Z", fill: color })));
};
exports.default = FilterIcon;
