"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var HeartIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c, _d = _a.strokeWidth, strokeWidth = _d === void 0 ? 1.5 : _d, _e = _a.isFilled, isFilled = _e === void 0 ? false : _e;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.7782 6.63881C16.7782 11.4999 9.57057 15.4346 9.26363 15.5971C9.18273 15.6407 9.0923 15.6634 9.00044 15.6634C8.90857 15.6634 8.81814 15.6407 8.73724 15.5971C8.4303 15.4346 1.22266 11.4999 1.22266 6.63881C1.22394 5.4973 1.67798 4.40291 2.48515 3.59574C3.29232 2.78857 4.3867 2.33454 5.52821 2.33325C6.96224 2.33325 8.2178 2.94992 9.00044 3.99228C9.78307 2.94992 11.0386 2.33325 12.4727 2.33325C13.6142 2.33454 14.7086 2.78857 15.5157 3.59574C16.3229 4.40291 16.7769 5.4973 16.7782 6.63881Z", fill: isFilled ? color : "", stroke: color, strokeWidth: strokeWidth })));
};
exports.default = HeartIcon;
