import { type TailwindBreakpointPrefix } from "@phiaco/phia-ui";

export const routes = {
  HOME: "/",
  PRODUCTS: "/products",
  ABOUT_PHIA: "/about-phia",
  CONTACT_AND_SUPPORT: "/contact-and-support",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms",
  PROFILE: "/profile",
  GIVEAWAY: "/giveaway",
  PREFERENCES: "/profile/preferences",
  SETTINGS: "/profile/settings",
  PRODUCT: "/product",
  BRAND: "/brand",
  PARTNER: "/partner",
  EXT_ONBOARDING: "/onboarding/extension",
  EXT_ONBOARDING_SELECT_SIZES: "/onboarding/extension/select-sizes",
  EXT_ONBOARDING_HOW_IT_WORKS: "/onboarding/extension/how-it-works",
  EXT_ONBOARDING_GET_STARTED: "/onboarding/extension/get-started",
  MARKETPLACE_ONBOARDING: "/onboarding",
  MARKETPLACE_ONBOARDING_HOW_IT_WORKS: "/onboarding/how-it-works",
  MARKETPLACE_ONBOARDING_PROFILE: "/onboarding/profile",
  MARKETPLACE_ONBOARDING_SELECT_SIZES: "/onboarding/select-sizes",
  MARKETPLACE_ONBOARDING_SELECT_PRICE_TIERS: "/onboarding/select-price-tiers",
  MARKETPLACE_ONBOARDING_SELECT_BRANDS: "/onboarding/select-brands",
  MARKETPLACE_ONBOARDING_GET_STARTED: "/onboarding/get-started",
  MOBILE_ENABLE_PHIA: "/mobile/enable-phia",
  MOBILE_ADD_PERMISSIONS: "/mobile/add-permissions",
  EXT_UNINSTALL: "/help-us-improve",
  FEEDBACK: "/feedback",
  BETA: "/beta",
  REFERRAL_SIGNUP: "/beta/signup",
  CATEGORIES: "/shop",
  EDITORIALS: "/collection",
  EXT_SIGNIN: "/extension-signin",
  OFFLINE: "/offline",
  EXTENSION_SITE_SUPPORT: "/extension-site-support",
  SHARED_SEARCH: "/s",
};

// The following routes require a user to be authenticated to visit them
export const privateRoutes = [
  routes.PROFILE,
  routes.PREFERENCES,
  routes.SETTINGS,
  routes.GIVEAWAY,
];

// Routes for the extension onboarding flow
export const EXTENSION_ONBOARDING_ROUTES = [
  routes.EXT_ONBOARDING,
  routes.EXT_ONBOARDING_GET_STARTED,
  routes.EXT_ONBOARDING_SELECT_SIZES,
  routes.EXT_ONBOARDING_HOW_IT_WORKS,
];

// Routes to exclude layout
export const ROUTES_WITHOUT_LAYOUT = [
  ...EXTENSION_ONBOARDING_ROUTES,
  routes.BETA,
  routes.MARKETPLACE_ONBOARDING_GET_STARTED,
  routes.MARKETPLACE_ONBOARDING_PROFILE,
  routes.MARKETPLACE_ONBOARDING_SELECT_SIZES,
  routes.MARKETPLACE_ONBOARDING_SELECT_PRICE_TIERS,
  routes.MARKETPLACE_ONBOARDING_SELECT_BRANDS,
  routes.REFERRAL_SIGNUP,
  routes.EXT_SIGNIN,
  routes.EXT_UNINSTALL,
  routes.FEEDBACK,
  routes.OFFLINE,
  routes.MOBILE_ENABLE_PHIA,
  routes.MOBILE_ADD_PERMISSIONS,
];

// Routes that are used by the extension in an iframe
export const EXTENSION_IFRAME_ROUTES = [routes.EXT_SIGNIN];

const supplementaryRoutes = [
  routes.PRIVACY_POLICY,
  routes.TERMS_AND_CONDITIONS,
  routes.ABOUT_PHIA,
  routes.CONTACT_AND_SUPPORT,
];

const profileRoutes = [routes.PROFILE, routes.PREFERENCES, routes.SETTINGS];

export const ROUTES_WITH_BACK_BUTTON = [
  routes.SETTINGS,
  routes.PREFERENCES,
  routes.PRODUCT,
  routes.GIVEAWAY,
];

export const ROUTES_TO_HIDE_SEARCH: Record<TailwindBreakpointPrefix, string[]> =
  {
    sm: [],
    md: [routes.HOME, routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS],
    lg: [routes.HOME, routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS],
    xl: [routes.HOME, routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS],
    "2xl": [routes.HOME, routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS],
  };
export const ROUTES_TO_HIDE_CATEGORY_MENU: Record<
  TailwindBreakpointPrefix,
  string[]
> = {
  sm: [
    ...supplementaryRoutes,
    ...profileRoutes,
    routes.GIVEAWAY,
    routes.PRODUCT,
    routes.EDITORIALS,
  ],
  md: [
    ...supplementaryRoutes,
    ...profileRoutes,
    routes.GIVEAWAY,
    routes.PRODUCT,
    routes.HOME,
    routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS,
    routes.EDITORIALS,
  ],
  lg: [
    ...supplementaryRoutes,
    ...profileRoutes,
    routes.GIVEAWAY,
    routes.PRODUCT,
    routes.HOME,
    routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS,
    routes.EDITORIALS,
  ],
  xl: [
    ...supplementaryRoutes,
    ...profileRoutes,
    routes.GIVEAWAY,
    routes.PRODUCT,
    routes.HOME,
    routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS,
    routes.EDITORIALS,
  ],
  "2xl": [
    ...supplementaryRoutes,
    ...profileRoutes,
    routes.GIVEAWAY,
    routes.PRODUCT,
    routes.HOME,
    routes.MARKETPLACE_ONBOARDING_HOW_IT_WORKS,
    routes.EDITORIALS,
  ],
};
