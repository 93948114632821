"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var GoogleIcon = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { className: className, width: "25", height: "24", viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M21.3908 12.2986C21.3908 11.6486 21.3325 11.0236 21.2242 10.4236H12.5908V13.9694H17.5242C17.3117 15.1153 16.6658 16.0861 15.695 16.7361V19.0361H18.6575C20.3908 17.4402 21.3908 15.0903 21.3908 12.2986Z", fill: "#4285F4" }),
        React.createElement("path", { d: "M12.5901 21.257C15.0651 21.257 17.1401 20.4362 18.6568 19.0362L15.6943 16.7362C14.8734 17.2862 13.8234 17.6112 12.5901 17.6112C10.2026 17.6112 8.18177 15.9987 7.46094 13.832H4.39844V16.207C5.90677 19.2029 9.00677 21.257 12.5901 21.257Z", fill: "#34A853" }),
        React.createElement("path", { d: "M7.46133 13.832C7.278 13.282 7.17383 12.6945 7.17383 12.0903C7.17383 11.4861 7.278 10.8986 7.46133 10.3486V7.97363H4.39883C3.75716 9.25101 3.42326 10.6608 3.42383 12.0903C3.42383 13.5695 3.778 14.9695 4.39883 16.207L7.46133 13.832Z", fill: "#FBBC05" }),
        React.createElement("path", { d: "M12.5901 6.56942C13.9359 6.56942 15.1443 7.03192 16.0943 7.94025L18.7234 5.31108C17.1359 3.83192 15.0609 2.92358 12.5901 2.92358C9.00677 2.92358 5.90677 4.97775 4.39844 7.97358L7.46094 10.3486C8.18177 8.18192 10.2026 6.56942 12.5901 6.56942Z", fill: "#EA4335" })));
};
exports.default = GoogleIcon;
