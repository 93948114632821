import { gql } from "@apollo/client";

export const LOG_ANONYMOUS_EVENT_TO_MIXPANEL_MUTATION = gql`
  mutation logAnonymousEventToMixpanel(
    $eventName: String!
    $deviceId: String!
    $distinctId: String!
    $platform: String!
    $platformVersion: String!
    $properties: String
  ) {
    logAnonymousEventToMixpanel(
      eventName: $eventName
      deviceId: $deviceId
      distinctId: $distinctId
      platform: $platform
      platformVersion: $platformVersion
      properties: $properties
    )
  }
`;

export const LOG_USER_EVENT_TO_MIXPANEL_MUTATION = gql`
  mutation logUserEventToMixpanel($eventName: String!, $properties: String) {
    logTrackingEventToMixpanel(eventName: $eventName, properties: $properties)
  }
`;

export const IDENTIFY_USER_IN_MIXPANEL_MUTATION = gql`
  mutation identifyUserInMixpanel {
    identifyUserInMixpanel
  }
`;

export const LOG_ANONYMOUS_EVENT_TO_CUSTOMER_IO_MUTATION = gql`
  mutation logAnonymousEventToCustomerIo(
    $eventName: String!
    $anonymousId: String!
    $properties: String
  ) {
    logAnonymousEventToCustomerIo(
      eventName: $eventName
      anonymousId: $anonymousId
      properties: $properties
    )
  }
`;

export const IDENTIFY_USER_IN_CUSTOMER_IO_MUTATION = gql`
  mutation identifyUserInCustomerIo {
    identifyUserInCustomerIo
  }
`;

export const LOG_USER_EVENT_TO_CUSTOMER_IO_MUTATION = gql`
  mutation logUserEventToCustomerIO(
    $eventName: String!
    $platform: String!
    $platformVersion: String!
    $properties: String
  ) {
    logUserEventToCustomerIO(
      eventName: $eventName
      platform: $platform
      platformVersion: $platformVersion
      properties: $properties
    )
  }
`;

export const LOG_USER_ATTRIBUTES_CUSTOMER_IO_MUTATION = gql`
  mutation addOrUpdateCustomerInCustomerIo(
    $email: String!
    $platform: String!
    $platformVersion: String!
    $attributes: String!
    $userId: String
  ) {
    addOrUpdateCustomerInCustomerIo(
      email: $email
      platform: $platform
      platformVersion: $platformVersion
      attributes: $attributes
      userId: $userId
    )
  }
`;
