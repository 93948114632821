"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Dialog = __importStar(require("@radix-ui/react-dialog"));
var react_visually_hidden_1 = require("@radix-ui/react-visually-hidden");
var framer_motion_1 = require("framer-motion");
var React = __importStar(require("react"));
var ANIMATION_DURATION = 0.3;
var animationGenerator = function (open, isAnimating) {
    var overlayAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: open && !isAnimating ? 1 : 0 },
        exit: { opacity: 0 },
        transition: { duration: ANIMATION_DURATION },
    };
    var contentAnimation = {
        initial: { x: "-100%", opacity: 0 },
        animate: {
            x: open && !isAnimating ? "0%" : "-100%",
            opacity: open && !isAnimating ? 1 : 0,
        },
        exit: { x: "-100%", opacity: 0 },
        transition: {
            type: "spring",
            stiffness: 300,
            damping: 30,
            duration: ANIMATION_DURATION,
        },
    };
    return { overlayAnimation: overlayAnimation, contentAnimation: contentAnimation };
};
var Sidebar = function (_a) {
    var children = _a.children, triggerButton = _a.triggerButton, contentZIndex = _a.contentZIndex, overlayZIndex = _a.overlayZIndex;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(false), isAnimating = _c[0], setIsAnimating = _c[1];
    var handleClose = function () {
        setIsAnimating(true);
        setTimeout(function () {
            setOpen(false);
            setIsAnimating(false);
        }, ANIMATION_DURATION * 1000);
    };
    var animations = animationGenerator(open, isAnimating);
    return (React.createElement(Dialog.Root, { open: open, onOpenChange: function (isOpen) { return (isOpen ? setOpen(true) : handleClose()); } },
        React.createElement(Dialog.Trigger, { asChild: true }, triggerButton),
        open && (React.createElement(Dialog.Portal, null,
            React.createElement(Dialog.Overlay, { className: "fixed inset-0 sm:bg-black sm:bg-opacity-10", style: { zIndex: overlayZIndex }, onClick: handleClose },
                React.createElement(framer_motion_1.motion.div, __assign({}, animations.overlayAnimation, { style: { zIndex: overlayZIndex }, className: "absolute inset-0" }))),
            React.createElement(Dialog.Content, { onOpenAutoFocus: function (e) { return e.preventDefault(); }, style: { zIndex: contentZIndex }, forceMount: true, asChild: true, className: "fixed bottom-0 left-0 top-0 w-full overflow-hidden bg-overlay-950 shadow-lg backdrop-blur-xl focus:outline-none sm:m-4 sm:max-w-[358px] sm:rounded-sm" },
                React.createElement(framer_motion_1.motion.div, __assign({}, animations.contentAnimation, { style: { zIndex: contentZIndex } }),
                    children,
                    React.createElement(react_visually_hidden_1.Root, null,
                        React.createElement(Dialog.Title, null, "Sidebar"))))))));
};
exports.default = Sidebar;
