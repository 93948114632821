"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var CarouselArrow = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 24 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M20.5153 12.2653L13.7653 19.0153C13.6949 19.0856 13.5995 19.1252 13.5 19.1252C13.4005 19.1252 13.3051 19.0856 13.2347 19.0153C13.1643 18.9449 13.1248 18.8495 13.1248 18.75C13.1248 18.6505 13.1643 18.555 13.2347 18.4847L19.3444 12.375H3.75C3.65054 12.375 3.55516 12.3355 3.48484 12.2651C3.41451 12.1948 3.375 12.0994 3.375 12C3.375 11.9005 3.41451 11.8051 3.48484 11.7348C3.55516 11.6645 3.65054 11.625 3.75 11.625H19.3444L13.2347 5.51528C13.1643 5.44491 13.1248 5.34948 13.1248 5.24996C13.1248 5.15045 13.1643 5.05502 13.2347 4.98465C13.3051 4.91429 13.4005 4.87476 13.5 4.87476C13.5995 4.87476 13.6949 4.91429 13.7653 4.98465L20.5153 11.7347C20.5502 11.7695 20.5778 11.8108 20.5967 11.8564C20.6156 11.9019 20.6253 11.9507 20.6253 12C20.6253 12.0492 20.6156 12.098 20.5967 12.1436C20.5778 12.1891 20.5502 12.2305 20.5153 12.2653Z", fill: color })));
};
exports.default = CarouselArrow;
