"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
// Makes passed function debounced, otherwise acts like useCallback
function useDebouncedCallback(callback, // function that will be debounced
delay //  debounce delay
) {
    var argsRef = (0, react_1.useRef)();
    var timeout = (0, react_1.useRef)();
    var cleanup = (0, react_1.useCallback)(function () {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }, []);
    (0, react_1.useEffect)(function () { return cleanup; }, [cleanup]);
    var debouncedCallback = (0, react_1.useCallback)(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        argsRef.current = args;
        cleanup();
        timeout.current = setTimeout(function () {
            if (argsRef.current) {
                callback.apply(void 0, argsRef.current);
            }
        }, delay);
    }, [callback, cleanup, delay]);
    return debouncedCallback;
}
exports.default = useDebouncedCallback;
