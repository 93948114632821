"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
var TextArea = function (_a) {
    var placeholder = _a.placeholder, value = _a.value, onChange = _a.onChange, rows = _a.rows, isDisabled = _a.isDisabled, className = _a.className;
    return (React.createElement("textarea", { value: value !== null && value !== void 0 ? value : "", placeholder: placeholder, rows: rows, disabled: isDisabled, onChange: function (e) { return onChange(e.target.value); }, className: (0, tailwind_merge_1.twMerge)("w-full rounded-sm px-4 py-2.5 \n            ".concat(isDisabled ? "bg-bg-tertiary" : "border border-border-opaque", " \n            text-para-sm placeholder-para-sm \n            placeholder-context-disabled focus:border-border-selected focus:outline-none"), className) }));
};
exports.default = TextArea;
