"use client";

import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";

// Define the type of the fallback object
type SearchParams = ReadonlyURLSearchParams;

// Create a fallback object that mimics ReadonlyURLSearchParams
const createFallbackSearchParams = (): SearchParams => {
  const urlSearchParams = new URLSearchParams();
  return urlSearchParams as unknown as ReadonlyURLSearchParams;
};

const usePhiaSearchParams = (): SearchParams => {
  const searchParams = useSearchParams();

  if (searchParams === null) {
    return createFallbackSearchParams();
  }

  return new Proxy(searchParams, {
    get: (target, prop: keyof ReadonlyURLSearchParams) => {
      const value = target[prop];
      if (typeof value === "function") {
        // Type-safe binding for methods
        return value.bind(target);
      }
      return value;
    },
  });
};

export default usePhiaSearchParams;
