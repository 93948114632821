"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIZE_TYPES = exports.SIZE_DATA = exports.Gender = void 0;
var Gender;
(function (Gender) {
    Gender["Women"] = "Women";
    Gender["Men"] = "Men";
})(Gender || (exports.Gender = Gender = {}));
// Size data pulled from: https://docs.google.com/document/d/1yNh2DtvWqzKrIfNy-3H2F_hptj4jNJPzKD_KPWIk0sM/edit
exports.SIZE_DATA = {
    Clothing: {
        Women: [
            {
                country: "US",
                sizes: [
                    "00",
                    "0",
                    "2",
                    "4",
                    "6",
                    "8",
                    "10",
                    "12",
                    "14 - 16",
                    "18",
                    "5XL",
                    "6XL",
                    "7XL",
                    "8XL",
                    "9XL",
                ],
            },
            {
                country: "EU",
                sizes: [
                    "30",
                    "32",
                    "34",
                    "36",
                    "38",
                    "40",
                    "42",
                    "44",
                    "46",
                    "48",
                    "50",
                    "52",
                    "54",
                    "56",
                    "58",
                ],
            },
            {
                country: "UK",
                sizes: [
                    "2",
                    "4",
                    "6",
                    "8",
                    "10",
                    "12",
                    "14",
                    "16",
                    "18 - 20",
                    "22",
                    "24",
                    "26",
                    "28",
                    "28",
                    "30",
                ],
            },
            {
                country: "IT",
                sizes: [
                    "34",
                    "36",
                    "38",
                    "40",
                    "42",
                    "44",
                    "46",
                    "48",
                    "50 - 52",
                    "54 - 56",
                    "56",
                    "58",
                    "60",
                    "60",
                    "60",
                ],
            },
            {
                country: "INTL",
                isDefault: true,
                sizes: [
                    "XXS",
                    "XS",
                    "S",
                    "S",
                    "M",
                    "M",
                    "L",
                    "L",
                    "XL",
                    "XXL",
                    "3XL",
                    "4XL",
                    "5XL",
                    "6XL",
                    "7XL",
                    "8XL",
                    "9XL",
                ],
            },
        ],
        Men: [
            { country: "US", sizes: ["34", "36", "38", "40", "42", "44", "46"] },
            { country: "EU", sizes: ["44", "46", "48", "50", "52", "54", "56"] },
            { country: "UK", sizes: ["34", "36", "38", "40", "42", "44", "46"] },
            { country: "IT", sizes: ["44", "46", "48", "50", "52", "54", "56"] },
            {
                country: "INTL",
                isDefault: true,
                sizes: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
            },
        ],
    },
    Pants: {
        All: [
            {
                country: "US",
                isDefault: true,
                sizes: [
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                ],
            },
            {
                country: "FR",
                sizes: [
                    "35",
                    "36",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                ],
            },
            {
                country: "KR",
                sizes: [
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                ],
            },
        ],
    },
    Shoes: {
        Women: [
            {
                country: "US",
                isDefault: true,
                sizes: ["3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
            },
            {
                country: "EU",
                sizes: ["34", "35", "36", "37", "38", "39", "40", "41", "42", "43"],
            },
            {
                country: "UK",
                sizes: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            },
            {
                country: "KR",
                sizes: [
                    "200",
                    "210",
                    "220",
                    "230",
                    "240",
                    "250",
                    "260",
                    "270",
                    "280",
                    "290",
                ],
            },
            {
                country: "IT",
                sizes: ["33", "34", "35", "36", "37", "38", "39", "40", "41", "42"],
            },
        ],
        Men: [
            {
                country: "US",
                isDefault: true,
                sizes: [
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                ],
            },
            {
                country: "EU",
                sizes: [
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                ],
            },
            {
                country: "UK",
                sizes: [
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                ],
            },
            {
                country: "KR",
                sizes: [
                    "220",
                    "230",
                    "240",
                    "250",
                    "260",
                    "270",
                    "280",
                    "290",
                    "300",
                    "310",
                    "320",
                    "330",
                ],
            },
            {
                country: "IT",
                sizes: [
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                ],
            },
        ],
    },
    Rings: {
        All: [
            {
                country: "US",
                isDefault: true,
                sizes: ["4", "5", "6", "7", "8", "9", "10", "11", "12", "13"],
            },
            {
                country: "EU",
                sizes: [
                    "46",
                    "48",
                    "50",
                    "52",
                    "54",
                    "56",
                    "58",
                    "60",
                    "62",
                    "64",
                    "66",
                    "68",
                ],
            },
            {
                country: "UK",
                sizes: ["H", "J", "L", "N", "P", "R", "T", "V", "X", "Z"],
            },
            {
                country: "Diameter",
                sizes: [
                    "14.9",
                    "15.5",
                    "16.3",
                    "17.1",
                    "17.9",
                    "18.8",
                    "19.6",
                    "20.4",
                    "21.2",
                    "21.8",
                ],
            },
        ],
    },
};
exports.SIZE_TYPES = Object.keys(exports.SIZE_DATA);
