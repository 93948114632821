"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var SearchIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none" },
        React.createElement("path", { d: "M12.0206 11.078L14.8759 13.9327L13.9326 14.876L11.0779 12.0207C10.0157 12.8722 8.69459 13.3353 7.33325 13.3333C4.02125 13.3333 1.33325 10.6453 1.33325 7.33334C1.33325 4.02134 4.02125 1.33334 7.33325 1.33334C10.6453 1.33334 13.3333 4.02134 13.3333 7.33334C13.3352 8.69467 12.8721 10.0158 12.0206 11.078ZM10.6833 10.5833C11.5293 9.71326 12.0018 8.54696 11.9999 7.33334C11.9999 4.75467 9.91125 2.66667 7.33325 2.66667C4.75459 2.66667 2.66659 4.75467 2.66659 7.33334C2.66659 9.91134 4.75459 12 7.33325 12C8.54687 12.0019 9.71318 11.5294 10.5833 10.6833L10.6833 10.5833V10.5833Z", fill: color })));
};
exports.default = SearchIcon;
