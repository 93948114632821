"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var tailwindBreakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
};
function useTailwindBreakpoint() {
    var _a = (0, react_1.useState)("sm"), screenSize = _a[0], setScreenSize = _a[1];
    (0, react_1.useEffect)(function () {
        var getBreakpoint = function (width) {
            if (width < tailwindBreakpoints.sm)
                return "sm";
            if (width < tailwindBreakpoints.md)
                return "md";
            if (width < tailwindBreakpoints.lg)
                return "lg";
            if (width < tailwindBreakpoints.xl)
                return "xl";
            return "2xl";
        };
        var handleResize = function () {
            setScreenSize(getBreakpoint(window.innerWidth));
        };
        // Debounce to limit the number of times the resize handler is called
        var debouncedResize = debounce(handleResize, 100);
        // Initial check
        handleResize();
        // Listen to window resize events
        window.addEventListener("resize", debouncedResize);
        // Clean up event listener on component unmount
        return function () { return window.removeEventListener("resize", debouncedResize); };
    }, []);
    return {
        screenSize: screenSize,
        isMobile: screenSize === "sm",
        isTablet: screenSize === "md",
        isDesktop: screenSize === "lg",
        isLargeDesktop: screenSize === "xl",
        isExtraLargeDesktop: screenSize === "2xl",
    };
}
// Utility debounce function with TypeScript support
function debounce(func, wait) {
    var timeout;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeout);
        timeout = setTimeout(function () { return func.apply(void 0, args); }, wait);
    };
}
exports.default = useTailwindBreakpoint;
