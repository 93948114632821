"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var DotsThreeVerticalIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 18 : _b, _c = _a.color, color = _c === void 0 ? "black" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M10.5 5.62988C10.5 5.33321 10.588 5.0432 10.7528 4.79653C10.9176 4.54985 11.1519 4.3576 11.426 4.24406C11.7001 4.13053 12.0017 4.10083 12.2926 4.15871C12.5836 4.21658 12.8509 4.35944 13.0607 4.56922C13.2704 4.779 13.4133 5.04628 13.4712 5.33725C13.5291 5.62822 13.4994 5.92982 13.3858 6.20391C13.2723 6.478 13.08 6.71227 12.8334 6.87709C12.5867 7.04191 12.2967 7.12988 12 7.12988C11.6022 7.12988 11.2206 6.97185 10.9393 6.69054C10.658 6.40924 10.5 6.02771 10.5 5.62988ZM12 10.5049C11.7033 10.5049 11.4133 10.5929 11.1666 10.7577C10.92 10.9225 10.7277 11.1568 10.6142 11.4309C10.5006 11.7049 10.4709 12.0065 10.5288 12.2975C10.5867 12.5885 10.7296 12.8558 10.9393 13.0655C11.1491 13.2753 11.4164 13.4182 11.7074 13.4761C11.9983 13.5339 12.2999 13.5042 12.574 13.3907C12.8481 13.2772 13.0824 13.0849 13.2472 12.8382C13.412 12.5916 13.5 12.3016 13.5 12.0049C13.5 11.6071 13.342 11.2255 13.0607 10.9442C12.7794 10.6629 12.3978 10.5049 12 10.5049ZM12 16.8799C11.7033 16.8799 11.4133 16.9679 11.1666 17.1327C10.92 17.2975 10.7277 17.5318 10.6142 17.8059C10.5006 18.0799 10.4709 18.3815 10.5288 18.6725C10.5867 18.9635 10.7296 19.2308 10.9393 19.4405C11.1491 19.6503 11.4164 19.7932 11.7074 19.8511C11.9983 19.9089 12.2999 19.8792 12.574 19.7657C12.8481 19.6522 13.0824 19.4599 13.2472 19.2132C13.412 18.9666 13.5 18.6766 13.5 18.3799C13.5 17.9821 13.342 17.6005 13.0607 17.3192C12.7794 17.0379 12.3978 16.8799 12 16.8799Z", fill: color })));
};
exports.default = DotsThreeVerticalIcon;
