"use client";

import { usePathname } from "next/navigation";

const usePhiaPathname = (): string => {
  const pathname = usePathname();
  return pathname || "";
};

export default usePhiaPathname;
