"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ShareIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 20 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M17.759 2.24083C17.6018 2.08375 17.4056 1.97141 17.1906 1.91538C16.9755 1.85935 16.7494 1.86167 16.5356 1.92208H16.5239L1.52855 6.47208C1.28445 6.54228 1.06753 6.68517 0.906669 6.88173C0.74581 7.07829 0.648646 7.31919 0.628115 7.57235C0.607584 7.82551 0.66466 8.07892 0.791744 8.29883C0.918828 8.51874 1.10989 8.69472 1.33948 8.80333L8.02855 11.9713L11.1965 18.6604C11.2965 18.8746 11.4557 19.0557 11.6554 19.1824C11.855 19.309 12.0867 19.3759 12.3231 19.3752C12.359 19.3752 12.395 19.3736 12.4309 19.3705C12.6832 19.3501 12.9231 19.253 13.1186 19.0923C13.3141 18.9315 13.4558 18.7149 13.5246 18.4713L18.0715 3.47599C18.0715 3.47208 18.0715 3.46818 18.0715 3.46427C18.1327 3.25096 18.136 3.0252 18.0811 2.81019C18.0262 2.59518 17.915 2.39866 17.759 2.24083ZM12.3301 18.1135L12.3262 18.1244L9.2512 11.633L12.9418 7.94161C13.0541 7.82345 13.1158 7.66611 13.1137 7.50313C13.1116 7.34015 13.0459 7.18444 12.9307 7.06919C12.8154 6.95394 12.6597 6.88827 12.4967 6.88618C12.3337 6.8841 12.1764 6.94576 12.0582 7.05802L8.36683 10.7486L1.87464 7.67365H1.88558L16.8746 3.12521L12.3301 18.1135Z", fill: color })));
};
exports.default = ShareIcon;
