"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var CloseIcon = function (_a) {
    var className = _a.className, onClick = _a.onClick, _b = _a.size, size = _b === void 0 ? 12 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, onClick: onClick, xmlns: "http://www.w3.org/2000/svg", width: size, height: size, viewBox: "0 0 12 12", fill: "none" },
        React.createElement("path", { d: "M9.77295 8.97699C9.87862 9.08266 9.93799 9.22599 9.93799 9.37543C9.93799 9.52487 9.87862 9.66819 9.77295 9.77387C9.66728 9.87954 9.52395 9.9389 9.37451 9.9389C9.22507 9.93891 9.08175 9.87954 8.97607 9.77387L5.99998 6.79683L3.02295 9.77293C2.91728 9.8786 2.77395 9.93797 2.62451 9.93797C2.47507 9.93797 2.33175 9.8786 2.22607 9.77293C2.1204 9.66726 2.06104 9.52393 2.06104 9.37449C2.06104 9.22505 2.1204 9.08173 2.22607 8.97605L5.2031 5.99996L2.22701 3.02293C2.12134 2.91726 2.06197 2.77393 2.06197 2.62449C2.06197 2.47505 2.12134 2.33173 2.22701 2.22605C2.33268 2.12038 2.47601 2.06102 2.62545 2.06102C2.77489 2.06102 2.91821 2.12038 3.02389 2.22605L5.99998 5.20309L8.97701 2.22558C9.08268 2.11991 9.226 2.06055 9.37545 2.06055C9.52489 2.06055 9.66821 2.11991 9.77389 2.22558C9.87956 2.33126 9.93892 2.47458 9.93892 2.62402C9.93892 2.77347 9.87956 2.91679 9.77389 3.02246L6.79685 5.99996L9.77295 8.97699Z", fill: color })));
};
exports.default = CloseIcon;
