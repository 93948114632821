"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var PhotoIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 32 : _b, _c = _a.color, color = _c === void 0 ? "#AFAFAF" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M26 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4ZM6 6H26V15.6725L22.9137 12.585C22.5387 12.2102 22.0302 11.9997 21.5 11.9997C20.9698 11.9997 20.4613 12.2102 20.0863 12.585L6.67125 26H6V6ZM26 26H9.5L21.5 14L26 18.5V26ZM12 15C12.5933 15 13.1734 14.8241 13.6667 14.4944C14.1601 14.1648 14.5446 13.6962 14.7716 13.1481C14.9987 12.5999 15.0581 11.9967 14.9424 11.4147C14.8266 10.8328 14.5409 10.2982 14.1213 9.87868C13.7018 9.45912 13.1672 9.1734 12.5853 9.05764C12.0033 8.94189 11.4001 9.0013 10.8519 9.22836C10.3038 9.45542 9.83524 9.83994 9.50559 10.3333C9.17595 10.8266 9 11.4067 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15ZM12 11C12.1978 11 12.3911 11.0586 12.5556 11.1685C12.72 11.2784 12.8482 11.4346 12.9239 11.6173C12.9996 11.8 13.0194 12.0011 12.9808 12.1951C12.9422 12.3891 12.847 12.5673 12.7071 12.7071C12.5673 12.847 12.3891 12.9422 12.1951 12.9808C12.0011 13.0194 11.8 12.9996 11.6173 12.9239C11.4346 12.8482 11.2784 12.72 11.1685 12.5556C11.0586 12.3911 11 12.1978 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11Z", fill: color })));
};
exports.default = PhotoIcon;
