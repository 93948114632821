"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ArrowRightIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.5306 10.4695C11.6005 10.5392 11.656 10.6219 11.6938 10.7131C11.7317 10.8043 11.7512 10.902 11.7512 11.0007C11.7512 11.0994 11.7317 11.1972 11.6938 11.2883C11.656 11.3795 11.6005 11.4623 11.5306 11.532L8.5306 14.532C8.46092 14.6019 8.37813 14.6574 8.28696 14.6952C8.1958 14.7331 8.09806 14.7526 7.99935 14.7526C7.90064 14.7526 7.8029 14.7331 7.71173 14.6952C7.62057 14.6574 7.53778 14.6019 7.4681 14.532L4.4681 11.532C4.3272 11.3911 4.24805 11.2 4.24805 11.0007C4.24805 10.8015 4.3272 10.6104 4.4681 10.4695C4.60899 10.3286 4.80009 10.2494 4.99935 10.2494C5.19861 10.2494 5.3897 10.3286 5.5306 10.4695L7.99997 12.9376L10.4693 10.4676C10.5391 10.398 10.6219 10.3428 10.7131 10.3052C10.8042 10.2677 10.9018 10.2484 11.0004 10.2486C11.0989 10.2487 11.1965 10.2683 11.2875 10.3063C11.3784 10.3442 11.4611 10.3996 11.5306 10.4695ZM5.5306 5.53198L7.99997 3.0626L10.4693 5.5326C10.6102 5.6735 10.8013 5.75265 11.0006 5.75265C11.1999 5.75265 11.391 5.6735 11.5318 5.5326C11.6727 5.39171 11.7519 5.20061 11.7519 5.00135C11.7519 4.8021 11.6727 4.611 11.5318 4.4701L8.53185 1.4701C8.46217 1.40018 8.37938 1.34471 8.28821 1.30685C8.19705 1.269 8.09931 1.24951 8.0006 1.24951C7.90189 1.24951 7.80415 1.269 7.71298 1.30685C7.62182 1.34471 7.53903 1.40018 7.46935 1.4701L4.46935 4.4701C4.32845 4.611 4.2493 4.8021 4.2493 5.00135C4.2493 5.20061 4.32845 5.39171 4.46935 5.5326C4.61024 5.6735 4.80134 5.75265 5.0006 5.75265C5.19986 5.75265 5.39095 5.6735 5.53185 5.5326L5.5306 5.53198Z", fill: color })));
};
exports.default = ArrowRightIcon;
