"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var BellIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 18 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M15.7563 11.634C15.1535 10.5972 14.8334 9.10903 14.8334 7.33333C14.8334 5.78624 14.2188 4.30251 13.1249 3.20854C12.0309 2.11458 10.5472 1.5 9.00007 1.5C7.45298 1.5 5.96925 2.11458 4.87528 3.20854C3.78132 4.30251 3.16674 5.78624 3.16674 7.33333C3.16674 9.10972 2.84799 10.5972 2.24521 11.634C2.12221 11.8455 2.05701 12.0856 2.05616 12.3303C2.05531 12.5749 2.11883 12.8155 2.24035 13.0278C2.3611 13.2402 2.53637 13.4166 2.74805 13.5387C2.95973 13.6607 3.20016 13.7241 3.44452 13.7222H5.95702C6.02593 14.4817 6.37633 15.1879 6.9394 15.7022C7.50246 16.2165 8.23749 16.5017 9.00007 16.5017C9.76266 16.5017 10.4977 16.2165 11.0608 15.7022C11.6238 15.1879 11.9742 14.4817 12.0431 13.7222H14.5556C14.7996 13.7237 15.0396 13.6602 15.2509 13.5381C15.4622 13.4161 15.6371 13.2399 15.7577 13.0278C15.8798 12.8158 15.9439 12.5754 15.9436 12.3307C15.9434 12.0861 15.8788 11.8458 15.7563 11.634ZM9.00007 14.8333C8.67989 14.8334 8.36952 14.7228 8.12149 14.5204C7.87346 14.3179 7.703 14.0359 7.63896 13.7222H10.3612C10.2972 14.0359 10.1267 14.3179 9.87866 14.5204C9.63063 14.7228 9.32026 14.8334 9.00007 14.8333ZM3.90702 12.0556C4.5216 10.8056 4.83341 9.21806 4.83341 7.33333C4.83341 6.22826 5.27239 5.16846 6.0538 4.38706C6.8352 3.60565 7.89501 3.16667 9.00007 3.16667C10.1051 3.16667 11.165 3.60565 11.9464 4.38706C12.7278 5.16846 13.1667 6.22826 13.1667 7.33333C13.1667 9.21736 13.4779 10.8056 14.0924 12.0556H3.90702Z", fill: color })));
};
exports.default = BellIcon;
