"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var RulerIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.8843 4.4088L11.5912 1.1163C11.4751 1.00017 11.3373 0.908049 11.1856 0.845199C11.0339 0.782349 10.8713 0.75 10.7071 0.75C10.5429 0.75 10.3803 0.782349 10.2287 0.845199C10.077 0.908049 9.93914 1.00017 9.82306 1.1163L1.11556 9.82317C0.999436 9.93925 0.907317 10.0771 0.844467 10.2288C0.781617 10.3805 0.749268 10.543 0.749268 10.7072C0.749268 10.8714 0.781617 11.034 0.844467 11.1857C0.907317 11.3374 0.999436 11.4752 1.11556 11.5913L4.40869 14.8838C4.52477 14.9999 4.66259 15.092 4.81428 15.1549C4.96597 15.2177 5.12856 15.2501 5.29275 15.2501C5.45695 15.2501 5.61953 15.2177 5.77122 15.1549C5.92291 15.092 6.06073 14.9999 6.17681 14.8838L14.8843 6.17692C15.0004 6.06084 15.0926 5.92302 15.1554 5.77133C15.2183 5.61964 15.2506 5.45705 15.2506 5.29286C15.2506 5.12866 15.2183 4.96608 15.1554 4.81439C15.0926 4.6627 15.0004 4.52488 14.8843 4.4088ZM5.29244 13.6463L2.35494 10.7088L3.99994 9.06255L5.46994 10.5325C5.61083 10.6734 5.80193 10.7526 6.00119 10.7526C6.20045 10.7526 6.39154 10.6734 6.53244 10.5325C6.67333 10.3916 6.75249 10.2006 6.75249 10.0013C6.75249 9.80204 6.67333 9.61094 6.53244 9.47005L5.06244 8.00005L5.99994 7.06255L7.46931 8.53255C7.61021 8.67344 7.80131 8.7526 8.00056 8.7526C8.19982 8.7526 8.39092 8.67344 8.53181 8.53255C8.67271 8.39165 8.75186 8.20055 8.75186 8.0013C8.75186 7.80204 8.67271 7.61094 8.53181 7.47005L7.06244 6.00005L7.99994 5.06255L9.46994 6.53255C9.61083 6.67344 9.80193 6.7526 10.0012 6.7526C10.2004 6.7526 10.3915 6.67344 10.5324 6.53255C10.6733 6.39165 10.7525 6.20055 10.7525 6.0013C10.7525 5.80204 10.6733 5.61094 10.5324 5.47005L9.06244 4.00005L10.7093 2.3538L13.6468 5.2913L5.29244 13.6463Z", fill: color })));
};
exports.default = RulerIcon;
