import { gql } from "@apollo/client";

export const LOG_IN_MUTATION = gql`
  mutation logIn(
    $idToken: String!
    $onboardingSource: String
    $deviceId: String!
    $distinctId: String!
    $platform: String!
    $platformVersion: String!
    $properties: String
  ) {
    logIn(
      idToken: $idToken
      onboardingSource: $onboardingSource
      deviceId: $deviceId
      distinctId: $distinctId
      platform: $platform
      platformVersion: $platformVersion
      properties: $properties
    ) {
      id
      email
      onboardingSource
      onboardedAt
      createdAt
    }
  }
`;

export const LOG_OUT_MUTATION = gql`
  mutation logOut(
    $deviceId: String!
    $distinctId: String!
    $platform: String!
    $platformVersion: String!
    $properties: String
  ) {
    logOut(
      deviceId: $deviceId
      distinctId: $distinctId
      platform: $platform
      platformVersion: $platformVersion
      properties: $properties
    )
  }
`;

export const GET_GOOGLE_ACCESS_TOKEN_MUTATION = gql`
  mutation getGoogleAccessToken($authCode: String!) {
    getGoogleAccessToken(authCode: $authCode) {
      refresh_token
      access_token
      token_type
      id_token
      scope
    }
  }
`;

export const IS_LOGGED_IN_QUERY = gql`
  query isLoggedIn {
    isLoggedIn
  }
`;
