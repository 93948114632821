"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
var Spinner = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? "black" : _b, _c = _a.size, size = _c === void 0 ? 24 : _c;
    return (React.createElement("svg", { className: (0, tailwind_merge_1.twMerge)("h-5 w-5 animate-spin", className), 
        // Replaced hard coded "24" with size prop so we can adjust for different button sizes
        width: size, height: size, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M19.1667 10C19.1667 11.9358 18.5538 13.8219 17.416 15.388C16.2781 16.9541 14.6737 18.1198 12.8327 18.718C10.9916 19.3162 9.00841 19.3162 7.16734 18.718C5.32628 18.1198 3.72185 16.9541 2.58401 15.388C1.44617 13.8219 0.833333 11.9358 0.833333 10C0.833333 8.06419 1.44617 6.17807 2.58401 4.61197C3.72185 3.04586 5.32628 1.88018 7.16735 1.28198C9.00841 0.683784 10.9916 0.683784 12.8327 1.28198", stroke: color, strokeWidth: "1.66667" })));
};
exports.default = Spinner;
