"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ThumbsDownIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "black" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.9888 9.8125L14.2388 3.8125C14.1931 3.44993 14.0166 3.1165 13.7425 2.87483C13.4684 2.63316 13.1154 2.49987 12.75 2.5H2C1.73478 2.5 1.48043 2.60536 1.29289 2.79289C1.10536 2.98043 1 3.23478 1 3.5V9C1 9.26522 1.10536 9.51957 1.29289 9.70711C1.48043 9.89464 1.73478 10 2 10H4.69125L7.0525 14.7238C7.09407 14.8068 7.15795 14.8767 7.237 14.9255C7.31604 14.9743 7.40711 15.0001 7.5 15C8.16304 15 8.79893 14.7366 9.26777 14.2678C9.73661 13.7989 10 13.163 10 12.5V11.5H13.5C13.7129 11.5001 13.9233 11.4548 14.1173 11.3673C14.3113 11.2798 14.4845 11.152 14.6253 10.9923C14.7662 10.8327 14.8714 10.645 14.9341 10.4415C14.9967 10.2381 15.0154 10.0237 14.9888 9.8125ZM4.5 9H2V3.5H4.5V9ZM13.875 10.3306C13.8284 10.3842 13.7708 10.4271 13.706 10.4564C13.6413 10.4856 13.571 10.5005 13.5 10.5H9.5C9.36739 10.5 9.24021 10.5527 9.14645 10.6464C9.05268 10.7402 9 10.8674 9 11V12.5C9.0001 12.8468 8.88002 13.1829 8.66021 13.4512C8.4404 13.7194 8.13443 13.9032 7.79437 13.9712L5.5 9.38188V3.5H12.75C12.8718 3.49996 12.9895 3.54439 13.0808 3.62494C13.1722 3.7055 13.231 3.81664 13.2463 3.9375L13.9963 9.9375C14.0056 10.0079 13.9996 10.0795 13.9787 10.1473C13.9578 10.2152 13.9224 10.2777 13.875 10.3306Z", fill: color })));
};
exports.default = ThumbsDownIcon;
