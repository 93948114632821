"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfilePicture = void 0;
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
var sizeClassMap = {
    lg: "w-24 h-24 border-1",
    md: "w-16 h-16 border-1",
    sm: "w-8 h-8",
};
var fontSizeMap = {
    lg: "43px",
    md: "32px",
    sm: "14px",
};
var marginTopMap = {
    lg: "12px",
    md: "8px",
    sm: "3px",
};
var ProfilePicture = function (_a) {
    var imageUrl = _a.imageUrl, _b = _a.firstName, firstName = _b === void 0 ? "" : _b, _c = _a.lastName, lastName = _c === void 0 ? "" : _c, email = _a.email, size = _a.size, className = _a.className, textClassName = _a.textClassName, onClick = _a.onClick;
    var sizeClasses = sizeClassMap[size];
    var sharedStyle = (0, tailwind_merge_1.twMerge)("".concat(sizeClasses, " border-border-transparent rounded-t-[100px] rounded-b-md"), className);
    var initials = React.useMemo(function () {
        if (!firstName && !lastName && email) {
            return email.charAt(0).toUpperCase();
        }
        var firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
        var lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
        return "".concat(firstInitial).concat(lastInitial) || (email === null || email === void 0 ? void 0 : email.charAt(0).toUpperCase());
    }, [firstName, lastName, email]);
    if (imageUrl) {
        return (React.createElement("img", { src: imageUrl, alt: "Profile", className: (0, tailwind_merge_1.twMerge)("".concat(sharedStyle, " object-cover")), onClick: onClick }));
    }
    return (React.createElement("div", { className: (0, tailwind_merge_1.twMerge)("".concat(sharedStyle, " flex items-center justify-center bg-bg-lightAccent")), onClick: onClick },
        React.createElement("p", { className: (0, tailwind_merge_1.twMerge)("font-serif text-cn-onColor", textClassName), style: {
                fontSize: fontSizeMap[size],
                marginTop: marginTopMap[size],
            } }, initials)));
};
exports.ProfilePicture = ProfilePicture;
exports.default = exports.ProfilePicture;
