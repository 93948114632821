"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var SmileyLaugh = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 25 : _b, _c = _a.color, color = _c === void 0 ? "black" : _c;
    return (React.createElement("svg", { width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), className: className, fill: "none", viewBox: "0 0 21 20", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { fill: color },
            React.createElement("path", { d: "m10.5.25c-1.92836 0-3.81343.571828-5.41681 1.64317-1.60337 1.07135-2.85306 2.59409-3.59101 4.37567-.737956 1.78158-.931038 3.74196-.554832 5.63326.376202 1.8913 1.304802 3.6286 2.668362 4.9922 1.36357 1.3636 3.10085 2.2922 4.99216 2.6684 1.89133.3762 3.85173.1831 5.63333-.5549 1.7815-.7379 3.3043-1.9876 4.3756-3.591 1.0714-1.6034 1.6432-3.4884 1.6432-5.4168-.0027-2.58502-1.0308-5.06339-2.8587-6.89128-1.8279-1.82788-4.3063-2.85599-6.8913-2.85872zm0 18c-1.63169 0-3.22674-.4839-4.58345-1.3904s-2.41413-2.195-3.03855-3.7025-.7878-3.16625-.46947-4.7666c.31832-1.60034 1.10406-3.07035 2.25784-4.22413 1.15379-1.15378 2.6238-1.93952 4.22414-2.25785 1.60039-.31833 3.25919-.15495 4.76659.46947 1.5075.62443 2.796 1.68185 3.7025 3.03856.9066 1.3567 1.3904 2.95175 1.3904 4.58345-.0025 2.1873-.8725 4.2843-2.4191 5.8309s-3.6436 2.4166-5.8309 2.4191zm-4.5-10.125c0-.2225.06598-.44001.1896-.62502.12362-.185.29932-.3292.50489-.41434.20556-.08515.43176-.10743.64999-.06402.21823.0434.41869.15055.57602.30788.15733.15734.26448.35779.30789.57602s.02113.44443-.06402.65-.22934.38127-.41435.50488c-.185.12362-.40251.1896-.62502.1896-.29836 0-.58451-.11853-.79549-.3295-.21098-.21098-.32951-.49713-.32951-.7955zm9 0c0 .2225-.066.44001-.1896.62502-.1236.185-.2993.3292-.5049.41434-.2055.08515-.4317.10743-.65.06402-.2182-.0434-.4187-.15055-.576-.30788-.1573-.15734-.2645-.35779-.3079-.57602s-.0211-.44443.064-.65c.0852-.20557.2294-.38127.4144-.50488.185-.12362.4025-.1896.625-.1896.2984 0 .5845.11853.7955.3295.211.21098.3295.49713.3295.7955z" }),
            React.createElement("path", { "clip-rule": "evenodd", d: "m6.67969 11.5c-.41422 0-.75.3358-.75.75.0003 2.3824 2.12534 4.1795 4.57161 4.1795 2.4463 0 4.5713-1.7967 4.5716-4.1791 0-.0001 0 .0001 0 0 0-.4142-.3358-.7504-.75-.7504zm6.76841 1.5h-5.8936c.36489 1.0784 1.49618 1.9295 2.9468 1.9295 1.4506 0 2.5819-.8511 2.9468-1.9295z", "fill-rule": "evenodd" }))));
};
exports.default = SmileyLaugh;
