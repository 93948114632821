"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ArrowLeftIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.2496 8.00019C14.2496 8.1991 14.1706 8.38987 14.0299 8.53052C13.8893 8.67117 13.6985 8.75019 13.4996 8.75019H4.31211L7.53211 11.9696C7.67301 12.1105 7.75217 12.3016 7.75217 12.5008C7.75217 12.7001 7.67301 12.8912 7.53211 13.0321C7.39122 13.173 7.20012 13.2521 7.00086 13.2521C6.80161 13.2521 6.61051 13.173 6.46961 13.0321L1.96961 8.53206C1.89969 8.46238 1.84422 8.37959 1.80636 8.28843C1.76851 8.19726 1.74902 8.09952 1.74902 8.00081C1.74902 7.9021 1.76851 7.80436 1.80636 7.7132C1.84422 7.62204 1.89969 7.53924 1.96961 7.46956L6.46961 2.96956C6.53938 2.8998 6.6222 2.84446 6.71335 2.8067C6.80451 2.76894 6.9022 2.74951 7.00086 2.74951C7.09953 2.74951 7.19722 2.76894 7.28838 2.8067C7.37953 2.84446 7.46235 2.8998 7.53211 2.96956C7.60188 3.03933 7.65722 3.12215 7.69498 3.2133C7.73273 3.30445 7.75217 3.40215 7.75217 3.50081C7.75217 3.59947 7.73273 3.69717 7.69498 3.78832C7.65722 3.87948 7.60188 3.9623 7.53211 4.03206L4.31211 7.25019H13.4996C13.6985 7.25019 13.8893 7.32921 14.0299 7.46986C14.1706 7.61051 14.2496 7.80128 14.2496 8.00019Z", fill: color })));
};
exports.default = ArrowLeftIcon;
