"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ArrowUpIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 20 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c, _d = _a.variant, variant = _d === void 0 ? "caret" : _d;
    return (React.createElement(React.Fragment, null, variant === "caret" ? (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.9132 13.1633C16.8261 13.2507 16.7227 13.3201 16.6087 13.3674C16.4947 13.4147 16.3726 13.4391 16.2492 13.4391C16.1258 13.4391 16.0036 13.4147 15.8897 13.3674C15.7757 13.3201 15.6722 13.2507 15.5851 13.1633L9.99997 7.57816L4.41325 13.1633C4.23713 13.3394 3.99826 13.4384 3.74918 13.4384C3.50011 13.4384 3.26124 13.3394 3.08512 13.1633C2.909 12.9872 2.81006 12.7483 2.81006 12.4993C2.81006 12.2502 2.909 12.0113 3.08512 11.8352L9.33512 5.58519C9.42222 5.49779 9.52571 5.42844 9.63967 5.38112C9.75362 5.33381 9.8758 5.30945 9.99918 5.30945C10.1226 5.30945 10.2447 5.33381 10.3587 5.38112C10.4727 5.42844 10.5762 5.49779 10.6632 5.58519L16.9132 11.8352C17.0006 11.9223 17.07 12.0258 17.1173 12.1397C17.1646 12.2537 17.189 12.3759 17.189 12.4993C17.189 12.6226 17.1646 12.7448 17.1173 12.8588C17.07 12.9727 17.0006 13.0762 16.9132 13.1633Z", fill: color }))) : (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_8121_57667)" },
            React.createElement("path", { d: "M12.8538 7.35366C12.8073 7.40014 12.7522 7.43702 12.6915 7.46218C12.6308 7.48735 12.5657 7.5003 12.5 7.5003C12.4343 7.5003 12.3693 7.48735 12.3086 7.46218C12.2479 7.43702 12.1927 7.40014 12.1463 7.35366L8.50003 3.70678V13.4999C8.50003 13.6325 8.44736 13.7597 8.35359 13.8535C8.25982 13.9472 8.13264 13.9999 8.00003 13.9999C7.86743 13.9999 7.74025 13.9472 7.64648 13.8535C7.55271 13.7597 7.50003 13.6325 7.50003 13.4999V3.70678L3.85378 7.35366C3.75996 7.44748 3.63272 7.50018 3.50003 7.50018C3.36735 7.50018 3.2401 7.44748 3.14628 7.35366C3.05246 7.25984 2.99976 7.13259 2.99976 6.99991C2.99976 6.86722 3.05246 6.73998 3.14628 6.64615L7.64628 2.14615C7.69272 2.09967 7.74786 2.06279 7.80856 2.03763C7.86926 2.01246 7.93433 1.99951 8.00003 1.99951C8.06574 1.99951 8.1308 2.01246 8.1915 2.03763C8.2522 2.06279 8.30735 2.09967 8.35378 2.14615L12.8538 6.64615C12.9003 6.69259 12.9372 6.74774 12.9623 6.80843C12.9875 6.86913 13.0004 6.9342 13.0004 6.99991C13.0004 7.06561 12.9875 7.13068 12.9623 7.19138C12.9372 7.25207 12.9003 7.30722 12.8538 7.35366Z", fill: "black" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_8121_57667" },
                React.createElement("rect", { width: "16", height: "16", fill: "white" })))))));
};
exports.default = ArrowUpIcon;
