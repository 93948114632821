"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_popover_1 = require("@radix-ui/react-popover");
var React = __importStar(require("react"));
var react_1 = require("react");
var react_day_picker_1 = require("react-day-picker");
var icons_1 = require("../../icons");
var util_1 = require("../../util");
var DatePicker = function (_a) {
    var value = _a.value, onChange = _a.onChange, label = _a.label, error = _a.error, _b = _a.placeholder, placeholder = _b === void 0 ? "MM/dd/yyyy" : _b, className = _a.className, containerClassName = _a.containerClassName;
    var _c = (0, react_1.useState)(""), inputValue = _c[0], setInputValue = _c[1];
    var _d = (0, react_1.useState)(undefined), selectedDate = _d[0], setSelectedDate = _d[1];
    var _e = (0, react_1.useState)(false), isPopoverOpen = _e[0], setIsPopoverOpen = _e[1];
    // Effect to set value initially (converting ISO to displayable "MM/dd/yyyy" format)
    (0, react_1.useEffect)(function () {
        if (value) {
            // Parse the ISO date string manually, avoiding any timezone-related shifts
            var parsedDate = new Date(value);
            // Extract the date components
            var year = parsedDate.getUTCFullYear();
            var month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
            var day = String(parsedDate.getUTCDate()).padStart(2, "0");
            // Manually format the date to "MM/DD/YYYY" for display
            var displayDate = "".concat(month, "/").concat(day, "/").concat(year);
            // Set the inputValue using the manually formatted date
            setInputValue(displayDate);
            // Set the selectedDate to the parsed Date object
            setSelectedDate(new Date(displayDate));
        }
    }, [value]);
    var handleDateSelect = function (date) {
        if (date) {
            // Create a local Date object at midnight (00:00:00) without altering the date based on the user's timezone
            var year = date.getFullYear();
            var month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
            var day = String(date.getDate()).padStart(2, "0");
            // Construct the ISO string manually to avoid timezone issues
            var isoDate = "".concat(year, "-").concat(month, "-").concat(day, "T00:00:00.000Z");
            // Display the date in "yyyy-MM-dd" format for the input field
            var displayDate = "".concat(month, "/").concat(day, "/").concat(year);
            setInputValue(displayDate);
            setSelectedDate(date);
            setIsPopoverOpen(false);
            if (onChange) {
                // Pass the manually constructed ISO date string
                onChange(isoDate);
            }
        }
    };
    return (React.createElement("div", { className: (0, util_1.customCN)("flex flex-col gap-2", containerClassName) },
        label && (React.createElement("label", { className: "label-sm text-cn-tertiary", htmlFor: "date-picker-input" }, label)),
        React.createElement("div", { className: "relative flex items-center" },
            React.createElement(react_popover_1.Popover, { open: isPopoverOpen, onOpenChange: setIsPopoverOpen },
                React.createElement(react_popover_1.PopoverTrigger, { asChild: true },
                    React.createElement("input", { readOnly: true, id: "date-picker-input", type: "text", value: inputValue, placeholder: placeholder, className: (0, util_1.customCN)("placeholder-context-disabled para-md w-full rounded-sm border border-border-opaque py-2 placeholder:para-md focus:border-border-selected focus:outline-none", "pl-4 pr-8", { "border-cn-negative": error }, className) })),
                React.createElement(react_popover_1.PopoverContent, { align: "start", side: "bottom", className: "z-10 rounded-md bg-white p-4 shadow-md" },
                    React.createElement(react_day_picker_1.DayPicker, { required: true, mode: "single", captionLayout: "dropdown", selected: selectedDate, onSelect: handleDateSelect, disabled: { after: new Date() }, classNames: {
                            root: "relative box-border",
                            day: "text-center w-8 h-8 font-inherit",
                            day_button: "flex justify-center items-center rounded-full border-transparent border-2 w-8 h-8 mx-1",
                            caption_label: "hidden",
                            nav_button: "inline-flex justify-center items-center w-9 h-9 text-inherit",
                            nav: "absolute top-0 right-0 flex items-center h-8",
                            chevron: "inline-block fill-current",
                            month_caption: "flex items-center h-8 font-bold",
                            months: "relative flex flex-wrap max-w-fit gap-8",
                            weekday: "text-center font-medium opacity-75 py-2 mx-2",
                            selected: "font-bold text-cn-accent [&>button]:border-cn-accent [&>button]:rounded-full [&>button]:border",
                            today: "font-bold text-cn-accent",
                            outside: "opacity-75",
                            disabled: "opacity-50 cursor-not-allowed",
                        } })))),
        error && (React.createElement("div", { className: "label-sm flex items-center gap-1 text-cn-negative" },
            React.createElement(icons_1.WarningCircleIcon, null),
            error))));
};
exports.default = DatePicker;
