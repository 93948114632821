"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ImageIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 24 : _b, _c = _a.color, color = _c === void 0 ? "#545454" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M20.25 4.25H3.75C3.35218 4.25 2.97064 4.40804 2.68934 4.68934C2.40804 4.97064 2.25 5.35218 2.25 5.75V19.25C2.25 19.6478 2.40804 20.0294 2.68934 20.3107C2.97064 20.592 3.35218 20.75 3.75 20.75H20.25C20.6478 20.75 21.0294 20.592 21.3107 20.3107C21.592 20.0294 21.75 19.6478 21.75 19.25V5.75C21.75 5.35218 21.592 4.97064 21.3107 4.68934C21.0294 4.40804 20.6478 4.25 20.25 4.25ZM20.25 5.75V15.3828L17.8059 12.9397C17.6666 12.8004 17.5013 12.6898 17.3193 12.6144C17.1372 12.539 16.9422 12.5002 16.7452 12.5002C16.5481 12.5002 16.3531 12.539 16.1711 12.6144C15.989 12.6898 15.8237 12.8004 15.6844 12.9397L13.8094 14.8147L9.68438 10.6897C9.4031 10.4086 9.02172 10.2507 8.62406 10.2507C8.22641 10.2507 7.84503 10.4086 7.56375 10.6897L3.75 14.5034V5.75H20.25ZM3.75 16.625L8.625 11.75L16.125 19.25H3.75V16.625ZM20.25 19.25H18.2466L14.8716 15.875L16.7466 14L20.25 17.5044V19.25ZM13.5 9.875C13.5 9.6525 13.566 9.43499 13.6896 9.24998C13.8132 9.06498 13.9889 8.92078 14.1945 8.83564C14.4 8.75049 14.6262 8.72821 14.8445 8.77162C15.0627 8.81502 15.2632 8.92217 15.4205 9.0795C15.5778 9.23684 15.685 9.43729 15.7284 9.65552C15.7718 9.87375 15.7495 10.1 15.6644 10.3055C15.5792 10.5111 15.435 10.6868 15.25 10.8104C15.065 10.934 14.8475 11 14.625 11C14.3266 11 14.0405 10.8815 13.8295 10.6705C13.6185 10.4595 13.5 10.1734 13.5 9.875Z", fill: color })));
};
exports.default = ImageIcon;
